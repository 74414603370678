import React from 'react'
import { H2, Paragraph1 } from '@styles/Global.styles'
import { GenericBannerContainer } from '@components/GenericBannerContainer'
import { Layout } from '@components/Layout'

const queryString = require('query-string');

interface PaymentFailureProps {
    location: any
}
class PaymentFailure extends React.Component<PaymentFailureProps, null>{

    renderError = (error: string) => {
        let err = error;
        switch (error) {
            case "retry_session_expired":
                err = "Your Session is Expired.";
                break;
            default:
                err = error;
        }
        return err;
    }
    render() {
        const qs = queryString.parse(this.props.location.search, { parseBooleans: true, parseNumbers: true });
        const error = qs.err;
        return (
            <Layout version="simple" location={this.props.location}>
                <GenericBannerContainer
                    padding={{
                        mobile: {
                            top: 20,
                            bottom: 20
                        },
                        desktop: {
                            top: 40,
                            bottom: 40
                        }
                    }}
                    backgroundColor="transparent">
                    <H2>Payment Failed</H2>
                    <Paragraph1>
                        {error !== 'undefined' && <div>{this.renderError(error)}</div>}
                    </Paragraph1>
                    <Paragraph1>
                        Your order has been saved. Please give us a call on <a href="tel:0800650111">0800 650 111</a> to complete your order.
                    </Paragraph1>
                </GenericBannerContainer>
            </Layout>
        )
    }

}

export default PaymentFailure;